<!--
 * @Author       : Hugo
 * @Date         : 2020-10-13 13:39:23
 * @LastEditTime : 2021-12-03 14:48:53
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/workteam/detailUnit.vue
 * @Description  :
 * @^_^          :
-->
<!-- 工作组详情单项 -->
<template>
  <div class="workteam_detail_unit" :style="{width}">
    <!-- <el-popover
      :placement="placement"
      trigger="click"
      visible-arrow="false"
      v-model="visible"
      popper-class="workteam_detail_unit_popover"
    >
      <div class="workteam_detail_unit_show_group" v-if="userDetail" >
        <div class="show_box">
          <div class="basic_group">
            <ContactDetailBasic :userData="userDetail"></ContactDetailBasic>
          </div>
          <div class="btn_group" v-if="userDetail && userData.userId != $tools.getUserId() && !isSystemUser()">
            <div class="btn cp dialog" v-if="userDetail.isfriend" @click="goToDialog();visible=false;">发起对话</div>
            <div class="btn cp apply" v-if="!userDetail.isfriend" @click="doApply();visible=false;">申请联系人</div>
          </div>
        </div>
      </div> -->
      <div class="content cp" slot="reference" @click="getUserInfo()" :style="{width}">
        <img :src="`${$avatar_url}${userData.thumbnail}@!small200`" alt="" class="avatar" :style="{width,height:width}" v-if="userData.thumbnail">
        <img :src="`${$avatar_url}${userData.pictures[0]}@!small200`" alt="" class="avatar" :style="{width,height:width}" v-if="userData.pictures && userData.pictures.length != 0">
        <!-- <div class="name" :style="{'font-size':fontsize,'padding-top':fontpaddingtop}">{{userData.chatUserName || userData.friendRemark ||userData.userName}}</div> -->
        <div class="name" :style="{'font-size':fontsize,'padding-top':fontpaddingtop}"
          v-if="userData.priorableName"
        >{{userData.priorableName()}}</div>
      </div>

    <!-- </el-popover>     -->
  </div>
</template>
<script>
// import ContactDetailBasic from '@/components/Contact/ContactDetailBasic.vue';
export default {
  components: {
    // ContactDetailBasic,
  },
  props: {
    userData: {
      type: Object,
      default: null,
    },
    width: {
      type: String,
      default: '44px',
    },
    fontsize: {
      type: String,
      default: '14px',
    },
    fontpaddingtop: {
      type: String,
      default: '9px',
    },
    placement:{
      type: String,
      default: 'right-end',
    }
  },
  data(){
    return{
      visible: false,
      userDetail: null,
    }
  },
  computed: {
    activeUserId(){
      // 激活的工作组联系人详情
      return this.$store.state.contact.workteam_detail_activeUserId;
    },
    /**
     * 关闭所有弹出框
     */
    close_all_popover(){
      return this.$store.state.close_all_popover;
    },


  },
  watch:{
    userData: {
      handler: function(newval){
        this.userData = newval;
        this.$set(this, 'userDetail', newval);
      },
      deep: true,
    },
    activeUserId: function(newVal){
      if(newVal != this.userData.userId){
        // 关闭未激活的联系人
        this.visible = false;
      }
    },
    /**
     * 监听关闭所有弹出框
     */
    close_all_popover: function(newval){
      if(newval){
        this.visible = false;
      }
    }

  },
  methods:{
    /**
     * 获取用户信息
     */
    async getUserInfo(){
      let chatType = this.$tools.getChatType();
      // let chatId = this.$route.params.id;
      let chatId = this.$api.moduleTask.getRouteParamsId();

      let userId = this.userData.userId || this.userData.chatId;
      this.$api.user.getInfo({userId});
      return;
      let data = {
        userId,
      }
      if(chatType == 1 && chatId){
        data.chatId = chatId;
      }
      let res = await this.$http.request('/project/getUserById', data);
      if(res.contents.user){
        this.$set(this, 'userDetail', res.contents.user);
      }
      this.$utils.setPopoverZIndex('.workteam_detail_unit_popover');
    },
    /**
     * 申请联系人
     */
    doApply(){
      // this.$api.common_open_add_friend_box();
      this.$store.commit('to_add_friend', this.userData);
    },
    /**跳转到会话 */
    goToDialog(){
      this.$api.common_goToSingleDialog(this.userData);
    },
    isSystemUser(){
      // 是否系统用户
      let status = false;
      const {userData} = this;
      if(userData){
        let userId = userData.userId || userData.chatId;
        status = this.$api.isSystemUser(userId);
      }
      return status;
    }

  },
  mounted(){
  }
}
</script>
<style lang="scss" scoped>
.workteam_detail_unit{
  // width: 90px;
  // padding-bottom: 33px;
  .show_group{
    @include bbox;
    width: 340px;
    padding: 20px;
    .show_box{
      width: 100%;
    }
  }
  .content{
    // width:90px;
    .avatar{
      // width: 90px;
      // height: 90px;
      border-radius: 4px;
      display: block;
      background-color: $img_color;
    }
    .name{
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // font-size:16px;

      font-weight:400;
      line-height: 20px;
      color:rgba(51,51,51,1);
      // padding-top:22px;
    }
  }
}
</style>
<style lang="scss">
  .workteam_detail_unit_show_group{
    @include bbox;
    width: 340px;
    padding: 20px;
    .show_box{
      width: 100%;
      .btn_group{
        @include flex;
        justify-content: center;
        width:100%;
        padding-top:12px;
        .btn{
          @include transition;
          padding:0 30px;
          line-height:40px;
          border:2px solid rgba(0,84,166,1);
          border-radius:4px;
          font-size:16px;

          font-weight:400;
          color:rgba(0,84,166,1);
          background-color: transparent;
          &:hover{
            background-color: #DCE7F1;
          }
        }
      }
    }
  }
</style>
