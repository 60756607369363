var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workteam_detail_unit", style: { width: _vm.width } },
    [
      _c(
        "div",
        {
          staticClass: "content cp",
          style: { width: _vm.width },
          attrs: { slot: "reference" },
          on: {
            click: function ($event) {
              return _vm.getUserInfo()
            },
          },
          slot: "reference",
        },
        [
          _vm.userData.thumbnail
            ? _c("img", {
                staticClass: "avatar",
                style: { width: _vm.width, height: _vm.width },
                attrs: {
                  src: `${_vm.$avatar_url}${_vm.userData.thumbnail}@!small200`,
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.userData.pictures && _vm.userData.pictures.length != 0
            ? _c("img", {
                staticClass: "avatar",
                style: { width: _vm.width, height: _vm.width },
                attrs: {
                  src: `${_vm.$avatar_url}${_vm.userData.pictures[0]}@!small200`,
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.userData.priorableName
            ? _c(
                "div",
                {
                  staticClass: "name",
                  style: {
                    "font-size": _vm.fontsize,
                    "padding-top": _vm.fontpaddingtop,
                  },
                },
                [_vm._v(_vm._s(_vm.userData.priorableName()))]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }